<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <h1 class="mr-sm-4 header-tablepage">Language</h1>
      </div>

      <div class="title-tabs mt-3">Language List</div>
      <div class="bg-white p-3">
        <b-row>
          <b-col cols="12" sm="10">
            <b-row class="align-items-center row-gap-lg">
              <b-col cols="2" class="text-center"></b-col>
              <b-col cols="2">Language Name </b-col>
              <b-col cols="2">Display Name </b-col>

              <b-col cols="1">Flag</b-col>
              <b-col cols="2">Status</b-col>
              <b-col cols="1"></b-col>
              <b-col cols="2"></b-col>

              <template v-for="(field, index) of list">
                <b-col
                  cols="2"
                  class="text-center"
                  :key="'sort-order' + field.id + '-' + index"
                >
                  <div class="content-between">
                    <font-awesome-icon
                      icon="chevron-circle-up"
                      :class="`icon-size ${
                        index == 0 ? 'disabled' : 'pointer'
                      }`"
                      @click="sortData(field, index, false, 'list')"
                    />
                    {{ index + 1 }}
                    <font-awesome-icon
                      icon="chevron-circle-down"
                      :class="`icon-size ${
                        index == list.length - 1 ? 'disabled' : 'pointer'
                      }`"
                      @click="sortData(field, index, true, 'list')"
                    />
                  </div>
                </b-col>
                <b-col cols="2" :key="'name' + field.id + '-' + index">
                  <!-- <div v-show="false">
                    {{ options }}
                  </div> -->
                  <InputSelect
                    class="mb-0"
                    :name="'lang-code' + field.id + '-' + index"
                    isRequired
                    :options="options"
                    v-model="field.name"
                    valueField="code"
                    textField="name"
                    @onDataChange="(val) => selectLang(val, index)"
                    :disabled="field.id == 1"
                  >
                    <template v-slot:option-first>
                      <b-form-select-option value="" disabled
                        >Language
                      </b-form-select-option>
                    </template>
                  </InputSelect>
                </b-col>
                <b-col cols="2" :key="'display_name' + field.id + '-' + index">
                  <!-- <div v-show="false">
                    {{ options }}
                  </div> -->
                  <InputText
                    textFloat=""
                    v-model="field.display_name"
                    placeholder="Display Name"
                    class="mb-0"
                  />
                </b-col>
                <b-col cols="1" :key="'flag' + field.id + '-' + index">
                  <span class="flag-lang"> {{ field.image_url }}</span>
                </b-col>
                <b-col cols="2" :key="'status' + field.id + '-' + index">
                  <b-form-checkbox
                    switch
                    v-model="field.active"
                    class="radio-active"
                    :value="1"
                    :unchecked-value="0"
                    size="md"
                    :disabled="field.id == 1"
                  >
                  </b-form-checkbox>
                </b-col>
                <b-col cols="1" :key="'trash-' + field.id + '-' + index">
                  <font-awesome-icon
                    v-if="field.id != 1"
                    icon="trash-alt"
                    class="cursor-pointer"
                    @click="deleteField(index, 'list')"
                /></b-col>

                <div
                  class="break-normal"
                  :key="'break-line' + field.id + index"
                ></div>
              </template>
              <b-col cols="1" class="text-center"> </b-col>
              <b-col cols="4" class="d-inline-flex">
                <b-button
                  variant="custom-link mr-auto"
                  @click="addField('list')"
                >
                  Add New Language
                </b-button>
              </b-col>
              <b-col cols="4"> </b-col>
              <b-col cols="1"> </b-col>
              <b-col cols="1"> </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <FooterAction @submit="submit()" routePath="/setting" />
    </div>
  </div>
</template>

<script>
import LangCode from "@/assets/lang/code.json";
import OtherLoading from "@/components/loading/OtherLoading";

export default {
  components: { OtherLoading },
  validations: {},

  data() {
    return {
      list: [],
      listNoRegister: [],
      listDelete: [],
      updateList: [],
      isLoading: true,
      pageDetail: {
        name: "",
        header: "",
        updated: "",
      },
      isDefault: null,
      selected: {
        is_register: [],
        is_not_register: [],
      },
      langOption: LangCode,
    };
  },
  async created() {
    // const page = DataType.find((el) => el.id == this.$route.params.id);
    // this.pageDetail = page;
  },
  watch: {
    langOption(val) {
      return val;
    },
  },
  computed: {
    options() {
      return this.langOption.map((el) => {
        return {
          ...el,
          disabled: this.list.find((b) => b.name == el.code) ? true : false,
        };
      });
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.isLoading = true;
      const register = await this.axios(`setting/display_language`);
      this.list = register.data.detail.sort(
        (a, b) => a.sort_order - b.sort_order
      );

      this.isLoading = false;
    },
    addField(type) {
      //   let other = this[type].findIndex((el) => el.id == 1);
      //   let clone = JSON.parse(JSON.stringify(this[type][other]));
      //   this[type].splice(other, 1);

      this[type].push({
        id: 0,
        name: "",
        image_url: "",
        sort_order: this[type].length,
        is_default: 0,
        active: 1,
      });
      //   clone.sort_order += 1;
      //   this[type].push(clone);
    },
    deleteField(index, key) {
      const dele = this[key].splice(index, 1);
      this.listDelete.push(dele[0].id);
    },
    async submit() {
      try {
        this.$bus.$emit("showLoading");

        const res = await this.axios.post(
          `setting/display_language`,
          this.list
        );
        this.getList();
        this.$bus.$emit("hideLoading");
        if (res.data.result == 1)
          this.successAlert().then(() => this.$router.push("/setting"));
        else this.errorAlert();
      } catch (error) {
        this.errorAlert(error.message);
        this.$bus.$emit("hideLoading");
      }
    },
    async sortData(items, index, val, key) {
      if (index == 0 && !val) {
        return;
      }

      if (val && index == this[key].length - 1) return;

      this[key].splice(index, 1);
      index = val ? index + 1 : index - 1;

      this[key].splice(index, 0, items);
      var index = 0;
      for (const fields of this[key]) {
        fields.sort_order = index;
        index++;
      }
      this.$nextTick(() => {
        this[key] = [...this[key]];
        // this.sortArray();
      });
    },
    selectLang(val, index) {
      let { flag, name } = this.langOption.find((el) => el.code == val);
      this.list[index].display_name = name;
      this.list[index].image_url = flag;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-size {
  font-size: 20px;
  color: #575757;
  cursor: pointer;
}
.icon-size.disabled {
  color: #bababa;
}
</style>
